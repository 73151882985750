<template>
  <div>
    <el-form>
      <el-form-item>
        <el-upload
          :headers="getHeaders()"
          ref="upload"
          action="/registryservice/files"
          :auto-upload="true"
          :on-success="uploadSuccess"
          :before-upload="beforeUpload"
          :limit="1"
        >
          <el-button slot="trigger" size="small" type="primary">Выбрать файл</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="Система координат файла">
        <coordinate-system
          class="coordinateSystem"
          @change-cs="changeCS"
          :options="userCSs"
        ></coordinate-system>
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="transformToPolygons" label="Преобразовать замкнутые контура в полигоны"></el-checkbox>
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="swapXYAxes" label="Поменять оси местами"></el-checkbox>
      </el-form-item>
      <el-form-item>
        <el-checkbox v-model="isFlatteningNeeded" label="Убрать z-координаты"></el-checkbox>
      </el-form-item>
      <el-collapse v-if="result.filename">
        Результат импорта файла"{{ result.filename }}"
        <el-collapse-item
          :title="result.message"
          v-if="result.description.length > 0">
          <div v-for="d in result.description">
          {{ d }}
            <br>
          </div>
        </el-collapse-item>
        <div v-else><br>{{result.message}}</div>
      </el-collapse>
    </el-form>
    <el-button @click="sendImport">{{ sendImportButtonText }}</el-button>
  </div>
</template>

<script>
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { RegistryFilesAPI } from '@/core/infrastructure/api/modules/RegistryFilesAPI'
import { DotNetSpatialServiceAPI } from '@/core/infrastructure/api/modules/DotNetSpatialServiceAPI'
import CoordinateSystem from '@/core/infrastructure/components/CoordinateSystem'

export default {
  name: 'fileDXFForm',
  components: { CoordinateSystem },
  props: {
    userCSs: {
      type: Array
    },
    importLayerGeometryField: {
      type: String,
      default: null
    }
  },
  data: function () {
    return {
      fileCS: null,
      fileName: null,
      originalFileName: null,
      transformToPolygons: false,
      loading: true,
      response: null,
      swapXYAxes: false,
      isFlatteningNeeded: false,
      result: {
        filename: null,
        message: null,
        description: null
      },
      sendImportButtonTextByDefault: 'Импортировать',
      sendImportButtonText: this.sendImportButtonTextByDefault || 'Импортировать'
    }
  },
  methods: {
    changeCS (newCS) {
      this.fileCS = newCS
    },
    beforeUpload (file) {
      this.originalFileName = file.name
      return true
    },
    async uploadSuccess (response, file, fileList) {
      this.response = response
      let fileData = await APIClient.shared.request(
        new RegistryFilesAPI.GetFileNameById(response.id)
      )
      this.fileName = `${fileData.guid}.${fileData.extension}`
    },
    getHeaders () {
      return {
        Authorization: localStorage.getItem('user-token')
      }
    },
    async sendImport () {
      this.sendImportButtonText = 'Загрузка...'
      let answer = await APIClient.shared.request(
        new DotNetSpatialServiceAPI.ConvertFromDxfFile({
          file_name: this.fileName,
          epsg: this.fileCS,
          geom_attr_id: this.importLayerGeometryField,
          need_to_convert_closed_line_to_polygon: this.transformToPolygons,
          swap_x_y_axes: this.swapXYAxes,
          is_flattening_needed: this.isFlatteningNeeded
        })
      ).catch((reason) => {
        // ошибка в работе импорта (бэк)
        this.$set(this, 'result', {
          filename: this.originalFileName,
          message: 'текст ошибки',
          description: [JSON.stringify(reason)]
        })
      })
      if (!answer.hasOwnProperty('features')) {
        // ошибка в обработке файла (бэк)
        this.$set(this, 'result', {
          filename: this.originalFileName,
          message: answer.message,
          description: answer.parameters
        })
      } else {
        // все хорошо
        this.$set(this, 'result', {
          filename: this.originalFileName,
          message: `Было добавлено ${answer.features.length} фич`,
          description: []
        })
        this.$emit('features-imported', answer, this.fileCS)
      }
      this.$refs['upload'].clearFiles()
      this.sendImportButtonText = this.sendImportButtonTextByDefault
    }
  }
}
</script>

<style scoped>

</style>
